.button {
    margin: 1em;
    text-align: center;
    width: 90%;
    button {
        border: none;
        border-radius: 2px;
        background-color: rgb(239 35 60 / 0.8);
        color: $antiflash-white;
        outline-color: green;
        padding: 15px 20px;
        &:hover {
            background-color: $fire-engine-red;
            transition: 200ms;
            transform: scale(1.1);
            box-shadow: 0 0 8px $cool-gray;
        }
    }
}
