.header {
    background: #edf2f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 790px) {
        position: fixed;
        width: 100%;
        z-index: 1;
        justify-content: flex-start;
    }

    &-h1 {
        margin: 0 1rem;
        font-size: 1.8em;

        &--red {
            color: $red-pantone;
        }
    }
}
