.menu-burger {
    font-size: 25px;
    margin: 0 1rem;
    @media screen and (min-width: 790px) {
        display: none;
    }

    &-navbar {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        & li {
            list-style-type: none;
            color: $antiflash-white;
        }
    }
}

.is-active {
    width: 100%;
    height: 100vh;
    background-color: rgb(217 4 41 / 0.8);
    position: absolute;
    left: 0;
    top: 0;

    svg {
        position: absolute;
        right: 10px;
        top: 20px;
        font-size: 32px;
        z-index: 1;

        path {
            color: $antiflash-white;
        }
    }
}

.hide {
    display: none;
}
