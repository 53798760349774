.homePage {
    height: 100%;

    &-contactForm-container {
        border: 1px solid;
        background-image: url("../../pictures/banner-1.jpg");
    }

    &-services {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 1200px) {
            height: 800px;
        }
        &-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (min-width: 790px) {
                max-width: 1200px;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    &-materiel {
        background-color: $cool-gray;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            color: $antiflash-white;
        }
        @media screen and (min-width: 1200px) {
            margin: 0 50px;
            justify-content: space-evenly;
        }

        h2 {
            color: $antiflash-white;
            margin: 1em 0;
            text-align: center;
        }

        &-process {
            width: 90%;
            @media screen and (min-width: 780px) {
                margin: 3em 0;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                @media screen and (min-width: 780px) {
                    flex-direction: row;
                    justify-content: center;
                    justify-content: space-between;
                }

                li {
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 150px;
                    &:hover {
                        path {
                            color: green;
                        }
                    }

                    span {
                        color: $fire-engine-red;
                        font-weight: 500;
                        margin: 5px 0;
                    }

                    p {
                        color: $antiflash-white;
                        text-align: center;
                        padding: 0 1em;
                    }

                    div {
                        font-size: 50px;
                        border-radius: 50px;
                        width: 100px;
                        height: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $antiflash-white;
                        box-shadow: 0 0 5px 1px $antiflash-white;
                        margin: 0.5em 0;

                        path {
                            color: $fire-engine-red;
                        }
                    }
                }
            }
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: center;
            @media screen and (min-width: 790px) {
                flex-direction: row;
            }
        }

        .materiel-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 3em 0;
            overflow: hidden;
            max-width: 350px;
            width: 90%;
            background-color: $antiflash-white;
            border-radius: 8px;
            box-shadow: 0 0 5px 1px $antiflash-white;

            &-img {
                width: auto;
                height: 267px;
                margin: 3em;

                & img {
                    width: 100%;
                    height: 100%;
                }
            }

            &-title {
                color: #8d99ae;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                margin: 1em 0;

                h3 {
                    margin-right: 10px;
                    color: $cool-gray;
                }

                svg {
                    path {
                        color: $red-pantone;
                    }
                }
            }
        }
    }

    &-contact {
        background-color: #edf2f4;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 500px;
        justify-content: center;

        &-profil {
            width: 90%;
            max-width: 500px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 0 5px 1px #8d99ae;

            &-img {
                width: 200px;
                height: 200px;
                margin: 1em;
                float: left;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    background-color: #edf2f4;
                    border-radius: 50%;
                    box-shadow: 0 0 5px 1px #8d99ae;
                }
            }

            &-presentation {
                padding: 1em;
                span {
                    color: $red-pantone;
                }

                h3 {
                    margin-bottom: 1em;
                }
            }

            &-contact {
                display: flex;
                flex-direction: column;
                align-items: center;

                &-icon {
                    font-size: 20px;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    path {
                        color: $fire-engine-red;
                    }
                }

                p {
                    margin: 1em 0;
                    text-align: left;
                }
            }
        }
    }

    &-transport {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 600px;

        h3 {
            text-align: center;
            margin-bottom: 1em;
        }

        &-content {
            width: 90%;
            @media screen and (min-width: 780px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                max-width: 1200px;
            }

            &-img {
                max-width: 900px;
                img {
                    width: 100%;
                }
            }

            &-txt {
                padding: 10px;

                p {
                    margin: 10px 0;
                }

                ul {
                    margin: 5px 0;
                    li {
                        list-style-type: none;
                    }
                }
            }
        }
    }
}

.materiel-card:after {
    content: "";
    border-bottom: 5px $fire-engine-red solid;
    width: 20px;
    transition: all 0.8s;
}

.materiel-card:after {
    top: 0px;
    border-bottom-width: 5px;
    position: relative;
}

.materiel-card:hover.materiel-card:after {
    width: calc(100%);
}
