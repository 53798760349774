.navBar {
    margin: 0 1em;
    @media screen and (max-width: 790px) {
        display: none;
    }
    ul {
        display: flex;
        li {
            list-style-type: none;
            margin: 0 1em;
            color: $cool-gray;
            &:hover {
                color: $fire-engine-red;
            }
        }
    }
}
