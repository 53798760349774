.card {
    width: 80%;
    max-width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 3em 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 10px 10px $cool-gray;
    @media screen and (min-width: 790px) {
        margin: 3em;
    }
    &:hover {
        box-shadow: 22px 22px $cool-gray;
        transform: translate3d(-8px, -8px, -8px);
        transition: all 300ms ease-in-out;

        path {
            color: green;
        }
    }

    &-icon {
        font-size: 30px;
        border: 1px solid #8d99ae;
        border-radius: 30px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        path {
            color: $fire-engine-red;
        }
    }

    &-title {
        text-align: center;
    }

    &-text {
        padding: 0 1em;
        text-align: center;
    }
}
