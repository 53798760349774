* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    color: $space-cadet;
}

html {
    background: $antiflash-white;
    font-size: 100%;
}

body {
    min-height: 100vh;
    width: 100%;
    font-size: 0.8em;
}

a {
    text-decoration: none;
    color: inherit;
}
